<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <!-- <v-list-item-avatar>
      <v-img
        :src="
          require('@/assets/vmd.svg')"
      />
    </v-list-item-avatar> -->
    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <v-img v-if="mini" :src="require('@/assets/logo_icon.svg')"/>
        <v-img v-else :src="require('@/assets/logo.svg')"/>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  // Utilities
  import {get, sync} from 'vuex-pathify'

  export default {
    name: 'DefaultDrawerHeader',

    computed: {
      version: get('app/version'),
      mini: sync('app/mini'),
    },
  }
</script>
